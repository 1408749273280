import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VContent } from 'vuetify/lib/components/VContent';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('Loader',{attrs:{"is-visible":_vm.loading}}),(!this.$route.path.includes('login'))?_c(VAppBar,{staticClass:"primary",attrs:{"app":""}},[_c(VToolbarTitle,{staticClass:"headline"},[(!_vm.isSala)?[_c('span',[_vm._v(" Painel de Indicadores - "),_c('img',{staticStyle:{"width":"120px","margin-left":"10px"},attrs:{"src":require("./assets/img/logo_nacional.png")}})]),_c('span',[_vm._v(" "+_vm._s(_vm.tipoMapa)+" ")]),_c('img',{staticStyle:{"width":"83px","margin-left":"10px"},attrs:{"src":require("./assets/img/sebrae.png")}})]:[_c('img',{staticStyle:{"width":"83px","margin-right":"10px"},attrs:{"src":require("./assets/img/sebrae.png")}}),_c('selo-logo'),_c('div',{staticClass:"infos-cidade"},[(_vm.isSala)?void 0:_vm._e(),_c('div',[_vm._v(_vm._s(_vm.getFirstName()))]),_c('div',[_vm._v("|")]),_c('div',{staticClass:"buttonSair",on:{"click":_vm.logout}},[_vm._v("sair")])],2)]],2),_c(VSpacer)],1):_vm._e(),_c(VContent,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }