import { routes as dashboard } from "@/modules/dashboard";
import { routes as mapa } from "@/modules/mapa";

import { routes as home } from "@/modules/home";
import { routes as indicadores } from "@/modules/indicadores";
import { routes as grupoIndicador } from "@/modules/indicadores/grupoIndicador";
import { routes as subGrupoIndicador } from "@/modules/indicadores/subGrupoIndicador";
import { routes as indicador } from "@/modules/indicadores/indicador";
import { routes as login } from "@/modules/auth";

export default [
  {
    path: "*",
    redirect:
      process.env.VUE_APP_ESTADO !== "SC" ? "/indicadores/game" : "/home",
  },
  ...dashboard,
  ...home,
  ...login,
  ...indicadores,
  ...grupoIndicador,
  ...subGrupoIndicador,
  ...indicador,
  ...mapa,
];
