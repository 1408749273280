import { atualUfDefinition } from "@/helpers/utils";
export const routes = [
  {
    path: "/dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "./components/Index"),
    name: "dashboard",
  },
  {
    path: "/indicadores/game",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "./components/IndicadoresGame"
      ),
    name: "indicadores.game",
    meta: {
      requiresAuth: atualUfDefinition.isSala ? true : false,
    },
  },
  {
    path: "/indicadores/primarios",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "./components/IndicadoresManuais"
      ),
    name: "indicadores.primarios",
  },
  {
    path: "/indicadores/execucao",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "./components/IndicadoresExecucao"
      ),
    name: "indicadores.execucao",
  },
];
